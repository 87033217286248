<template>
    <div>
        <b-card> 
            <b-row>
                <b-col md="2" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50"> </label>
                        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                            class="w-50" />
                    </b-form-group>
                </b-col>
                <b-col md="4" sm="8" class="my-1">

                </b-col>
                <b-col md="6" class="my-1">
                    <b-form-group label="" label-cols-sm="3" label-align-sm="right" label-size="sm"
                        label-for="filterInput" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input id="filterInput" v-model="filter" type="search"
                                placeholder="Type to Search" />
                            <b-input-group-append>
                                <b-button :disabled="!filter" @click="filter = ''">
                                    Clear
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                </b-col>

                <b-col cols="12">
                    <b-table   hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                        :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn"
                        @filtered="onFiltered">

                        <template #cell(id)="data">
                            <b-row class="bg pt-1">
                                <b-col md="1">
                                    <b-avatar rounded="" size="75px" :src="data.item.foto" class="center" />
                                </b-col>
                                <b-col md="3">
                                    <small>Nama</small>
                                    <h6>{{data.item.nm_lengkap}}</h6>
                                    <small>level</small>
                                    <h6> {{data.item.level_name}}</h6>
                                </b-col>
                                <b-col md="3">
                                    <small>email</small>
                                    <h6>{{data.item.email}}</h6>
                                    <small>No Hp</small>
                                    <h6><a :href="'https://api.whatsapp.com/send?phone='+ data.item.no_hp"
                                            target="_blank">{{data.item.no_hp}}</a></h6>
                                </b-col>
                                <b-col md="3">
                                    <small>Admin Bidang </small>
                                    <h6>{{data.item.nama_bidang}}</h6>
                                    <div v-if="data.item.nama_bidang != null ">
                                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block size="sm"
                                            v-if="data.item.st =='1'" @click="updateSatatus(data.item.id)"
                                            variant="primary">
                                            <feather-icon icon="CheckSquareIcon" size="20" /> Aktif
                                        </b-button>
                                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block size="sm"
                                            v-if="data.item.st !='1'" @click="updateSatatus(data.item.id)"
                                            variant="warning">
                                            <feather-icon icon="CheckSquareIcon" size="20" /> Non Aktif
                                        </b-button>
                                    </div>
                                    <div v-if="data.item.nama_bidang == null ">
                                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block size="sm"
                                            v-b-modal.modal-2 v-if="data.item.st !='1'" @click="addLevel(data.item)"
                                            variant="primary">
                                            <feather-icon icon="UserPlusIcon" size="20" class="mr-1" /> Set Level Bidang
                                        </b-button>
                                    </div>
                                </b-col>
                                <b-col md="2" class="pt-1" v-if="data.item.nama_bidang != null ">
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block size="sm"
                                        @click="edit(data.item)" variant="warning" v-b-modal.modal-1>
                                        <feather-icon icon="CheckSquareIcon" size="15" /> Edit
                                    </b-button>
                                    <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" block size="sm"
                                        @click="hapusData(data.item.id)" variant="danger">
                                        <feather-icon icon="CheckSquareIcon" size="15" /> Hapus
                                    </b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="6">
                </b-col>
                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
                        size="sm" class="my-0" />
                </b-col>
            </b-row>
        </b-card>
        <editVue :rs_user=rs_user :bidang=bidang @simpanBidang=simpanBidang></editVue>
        <addVue :rs_user=rs_user :bidang=bidang @simpanBidang=simpanBidang></addVue>
    </div>
</template>

<script>
    import {
        BCard,
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BModal,
        VBModal,
        BAlert
    } from 'bootstrap-vue'
    import Base from '@/config/Mixins_base'
    import axios from '@/config/Axios'
    import Ripple from 'vue-ripple-directive'
    import editVue from './edit.vue'
    import addVue from './add.vue'
    export default {
        components: {
            BTable,
            BCard,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BModal,
            BButton,
            VBModal,
            BAlert,
            editVue,
            addVue
        },
        mixins: [Base],
        data() {
            return {
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {},
                fields: [{
                        key: 'id',
                        label: '',
                    },

                ],
                bidang: [],
                items: [],
                user_key: '',
                rs_user: {}
            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.totalRows = this.items.length;
            this.getbidang();
            this.load();
        },
        methods: {
            addLevel(val) {
                this.rs_user = val
            },
            edit(val) {
                this.rs_user = val
            },
            async simpanBidang(user, id_bidang) {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/acl/admin_bidang/act_bidang',
                        data: {
                            id: user.id,
                            id_bidang: id_bidang.id_bidang
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.notification('info', "Info", response.data.message);
                        self.load();
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async hapusData(id) {
                if (confirm("Apakah anda yakin akan menghapus akses bidang user ini..?") == true) {
                    const self = this;
                    await axios({
                            method: 'POST',
                            url: '/api/acl/admin_bidang/delete_data',
                            data: {
                                id: id
                            },
                            headers: {
                                'Authorization': self.isAuthenticated
                            }
                        })
                        .then(function (response) {
                            self.notification('warning', "Error", response.data.message);
                            self.load();
                        }).catch(err => {
                            self.pesan = err.message;
                            self.notification('warning', "Error", err.message);
                        });
                }

            },
            async updateSatatus(id) {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/acl/admin_bidang/status_update',
                        data: {
                            id: id
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.notification('warning', "Info", response.data.message);
                        self.load();

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async getbidang() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/acl/admin_bidang/bidang',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.bidang = response.data.result;
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async load() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/acl/admin_bidang/load',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.items = response.data.result;
                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`
                this.infoModal.content = JSON.stringify(item, null, 2)
                this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
    }
</script>
<style lang="scss">
    .bg {
        background: #b5bbbf4d;
    }
</style>