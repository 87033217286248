<template>
    <div>
        <!-- basic modal -->
        <b-modal id="modal-1" hide-footer no-close-on-backdrop content-class="shadow"
            :title="'Edit Level Bidang - User ' + rs_user.nm_lengkap">
            <br>
            {{rs_user }}
            <b-row>
                <b-col md="12">
                    <small>Nama</small>
                    <h4>{{rs_user.nm_lengkap }}</h4>
                    <small>Level </small>
                    <h4>{{rs_user.level_name }}</h4>

                    <small>Bidang Saat Ini </small>
                    <h4>{{rs_user.nama_bidang }}</h4>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="12">
                    <b-form-group label="Update Bidang Menjadi" label-for="basicInput">
                        <v-select v-model="id_bidang" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            label="nama_bidang" :options="bidang" />
                    </b-form-group> 
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="6">
                    <b-button class="mb-1 " variant="outline-primary" block type="submit" @click.prevent="actSave"
                        size="sm">
                        <i class="fa fa-save mr-1"></i> Simpan
                    </b-button>
                </b-col>
                <b-col cols="6">
                    <b-button class="  " variant="outline-warning" block @click="$bvModal.hide('modal-1')" size="sm">
                        <i class="fa fa-close mr-1"></i> Keluar</b-button>
                </b-col>
            </b-row>
        </b-modal>


    </div>
</template>

<script>
    import {
        BModal,
        BButton,
        VBModal,
        BAlert,
        BFormGroup
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import vSelect from 'vue-select'
    export default {
        components: {
            BButton,
            BModal,
            BAlert,
            BFormGroup,
            vSelect
        },
        data() {
            return {
                id_bidang: {}
            }
        },
        props: {
            rs_user: {},
            bidang: {}
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },
        emits: ["simpanBidang"],
        methods: {
            actSave() {
                this.$root.$emit('bv::hide::modal', 'modal-1');
                this.$emit("simpanBidang", this.rs_user, this.id_bidang)
            }
        }
    }
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>